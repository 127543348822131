  export default {
  setCookie(name,value,days) {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  },
  getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  },
  removeCookie(key){
      document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  },
  setStorage(key,value){
      if (typeof(Storage) == "undefined"){
          alert('Your browser does not support local storage!\nPlease update your browser');
          return;
      }
      localStorage.setItem(key,JSON.stringify(value));
  },
  getStorage(key){
      if (typeof(Storage) == "undefined"){
          alert('Your browser does not support local storage!\nPlease update your browser');
          return '';
      }
      return JSON.parse(localStorage.getItem(key));
  },
  hasPermission(pname){
      var ps = this.getStorage('permissions');
      if (ps==undefined || ps.length==0)
        return false;
      for (var i=0;i<ps.length;i++)
        if (ps[i].toLowerCase()==pname.toLowerCase())
            return true;
    return false;
  }
}