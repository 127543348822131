import Vue from "vue";
import App from "./App.vue";
import vSelect from 'vue-select'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false;
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('v-select', vSelect);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
