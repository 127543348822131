import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from '../cookies.js';

Vue.use(VueRouter);
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import("../views/Dashboard/Dashboard.vue"),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import("../views/Logout.vue"),
  },  
  {
    path: '/curation/:id?',
    name: 'curation',
    component: () => import("../views/Curation/Curation.vue"),
  }  
];

function isAuthenticated() {
  var t =Cookies.getCookie('t'); 
  return !(t==null || t.length<10);
}

const router = new VueRouter({
  routes,
});

router.beforeEach( (to,from,next) => {
  if (to.name !== 'login' && !isAuthenticated())
    next({ name: 'login' })
  else if (to.name=='login' && isAuthenticated())
    next({ name: 'dashboard' })
  else
    next()
}
);

export default router;
