<template>
        <li :class="'nav-item px-3'+(mobileOnly?' visonlymobile':'')" v-if="allow">
            <div :class="'nav-link'+(routeName==$route.name?' nav-link-active':'')" @click="goto(path)">
                       <b-icon :icon="icon"></b-icon> {{title}}
            </div>
        </li>
</template>
<script>
import cookies from '../../cookies';

export default {
  data(){
  return{
      allow: false
  }
  },
  props: ['icon','title','path','permission','mobileOnly','routeName'],
  beforeMount(){
    if (this.permission==null || typeof(this.permission)=='undefined')
      this.allow =true;
    else
      this.allow=cookies.hasPermission(this.permission);
  },
  methods: {
        goto(path){
          if (this.$route.name == this.routeName) return;
      this.$router.replace(path);
    }

  }
}
</script>
<style scoped>
.nav-link:hover {
    background-color: rgba(255,255,255,0.25);
    color: white;
}
.nav-link-active{
    background-color: rgba(255,255,255,0.25);
    color: white;
}
.nav-link{
  color: white;
}
</style>
<style scoped>
@media (min-width: 641px) {
    .visonlymobile{
        display: none;
    }
}
</style>