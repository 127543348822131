<template>
<div>
  <div class="top-row pl-4 navbar navbar-dark">
    <a class="navbar-brand" href="/">Addcell</a>
    <button class="navbar-toggler" @click="ToggleNavMenu">
        <span class="navbar-toggler-icon"></span>
    </button>
</div>

<div :class="collapseNavMenu?'collapse':''" @click="ToggleNavMenu">
    <ul class="nav flex-column">
      <NavLink title="Dashboard" icon="house-fill" path="/" routeName='dashboard'/>
      <NavLink title="Curation" icon="credit-card-2-front-fill" path="/curation" permission="curation" routeName='curation'/>
      <NavLink title="Logout" icon="box-arrow-right" path="/logout" mobileOnly="true"/>
    </ul>
</div>
</div>
</template>
<style src="./NavMenu.css" scoped></style>
<script>
import cookies from '../../cookies.js';
import NavLink from './NavLink.vue';

export default {
  data(){
    return {
      collapseNavMenu: true,
      allowCuration: false
    }
  },
  components: {
    NavLink
  },
  beforeMount(){
    this.allowCuration = cookies.hasPermission('curation');
  },
  methods:{
    ToggleNavMenu(){
        this.collapseNavMenu = this.collapseNavMenu?false:true;
    }
  }
}
</script>
