<template>
  <div id="app">
  <div class="page" v-if="$route.name!='login'">
    <div class="sidebar">
        <NavMenu></NavMenu>
    </div>
    <div class="main">
        <div class="top-row px-4">
            <span>
                Hello {{realName}},
                <span @click="logout" class="logout">Logout</span>
            </span>
        </div>
        <div class="content px-4" style="padding-top: 1rem;">
            <router-view></router-view>
        </div>
    </div>
    </div>
    <router-view v-if="$route.name=='login'"></router-view>
  </div>
</template>

<script>
import cookies from './cookies.js';
import NavMenu from './components/NavMenu/NavMenu.vue';

export default {
  name: 'App',
  data: ()=>{
    return {
      realName: ''
    }
  },
  components:{
    NavMenu
  },
  mounted(){
      this.realName = cookies.getStorage('realname');
  },
  methods:{
    logout(){
      this.$router.replace('/logout');
    }
  },
  watch: {
        '$route' (){
            if (this.$route.name=='login')
              document.body.className='login';
            else
              document.body.className='';
    }
  }
  
}</script>

<style>
body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh !important;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
body.login{
     background-color: #17a2b8 !important;
}
.PageTitle{
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
<style  src="./app.css" scoped></style>
